<template>
  <v-dialog
    persistent
    width="auto"
    height="auto"
    max-width="95%"
    v-if="dialogEvalListaGasto && data"
    v-model="dialogEvalListaGasto"
  >
    <v-card
      :class="'mx-auto'"
      :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
      elevation-6
      width="95%"
      max-width="95%"
    >
      <v-card-title
        style="height: 50px"
        :style="
          'color: ' +
          paletColor.buttons.cancel.text +
          ';position: sticky;top: 0; z-index: 2;background-color: ' +
          paletColor.card.backgroundgral +
          ';' +
          'paletColor.card.backgroundgral;padding: 16px;font-size: 18px;font-weight: bold;'
        "
      >
        <v-row>
          <v-col>{{ title }}</v-col>
          <v-col align="right" cols="1"
            ><v-btn
              :style="
                'background-color: ' + paletColor.card.backgroundgral + ';'
              "
              density="compact"
              icon="mdi-close-circle-outline"
              @click="cerrar"
            ></v-btn
          ></v-col>
        </v-row>
      </v-card-title>

      <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
        <v-row dense>
            <v-col cols="6" align="left">
                <v-btn v-if="data.pagina > 1"
                     icon="mdi-page-first"  density="comfortable"
                    @click="cargar_anterior"/>
                </v-col>
                <v-col cols="6" align="right">
                    <v-btn v-if="data.pagina < data.siguiente && data.pagina < data.total_paginas" 
                     icon="mdi-page-last"  density="comfortable"
                @click="cargar_siguiente"/>
                </v-col>
            </v-row>
        <v-row dense>
            
          <v-col cols="4" class="ps-3 text-center text-caption">
            <v-card style="background-color: #FFE8E8;">Fuera de turno ({{ data.totales.cantidad_fueraturno }})<br>{{ formatoMonedaChile(data.totales.total_fueraturno) }}</v-card></v-col>
          <v-col cols="4" class="ps-3 text-center text-caption">
            <v-card style="background-color: #FFF1C0;">Fuera de carrera ({{ data.totales.cantidad_fueracarrera }})<br>{{ formatoMonedaChile(data.totales.total_fueracarrera) }}</v-card></v-col>
          <v-col cols="4" class="ps-3 text-center text-caption">
            <v-card style="background-color: #E6FFEE;">En carrera ({{ data.totales.cantidad_encarrera }})<br>{{ formatoMonedaChile(data.totales.total_encarrera) }}</v-card></v-col>
          <v-col cols="6" class="ps-3 text-right"><b>Total</b></v-col>
          <v-col cols="6" class="ps-3 text-right"><b>({{ data.totales.cantidad }}) {{ formatoMonedaChile(data.totales.total) }}</b></v-col>
          <v-col
            cols="12"
            md="4"
            v-for="(tag, index) in data.detalle"
            :key="index"
          >
            <cmp_card_tag :tag="tag"/>
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-card>
  </v-dialog>
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="cerrar" />
</template>

<script>
import {
  obtenerJsonGET,
  paletColor,
  isMobile,
  formatoMonedaChile,
} from "@/helpers/helpers";
import cmp_card_tag from "@/components/tag/card_tag.vue";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
export default {
  data: () => ({
    dialogEvalListaGasto: false,
    data: null,
    periodo: null,
    fec_ini: null,
    fec_fin: null,
    title: "Listado de TAG",
    paletColor: paletColor,
    formatoMonedaChile: formatoMonedaChile,
    isMobile: isMobile,
  }),
  components: {
    mensajeUsuario,
    cmp_card_tag,
  },
  methods: {
    obtener_lista_tags: async function(pagina = 1, porpagina = 9){
        this.data = null;
      let param = {};
      if(this.periodo){
        param.periodo = this.periodo;
      }
      if(this.fec_ini){
        param.fecini = this.fec_ini;
      }
      if(this.fec_fin){
        param.fecfin = this.fec_fin;
      }
      param.pagina = pagina;
      param.porpagina = porpagina;
      let data = await obtenerJsonGET("/tagautopista/obtener_gasto_tag_conductor/",param);
      console.log(data);
      if (data.error == 0) {
        this.data = data.data;
        
        if(this.data.totales.total <= 0){
          this.$refs.mensajeUsuarioSalir.mostrar("No hay TAGS en el período seleccionado.");
        }
      } else {
        this.dialogEvalListaGasto = false;
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    cargar_siguiente: async function(){
        if(this.data.pagina < this.data.siguiente && this.data.pagina < this.data.total_paginas){
            this.obtener_lista_tags(this.data.siguiente);
        }else{
            this.$refs.mensajeUsuarioSalir.mostrar("No hay mas TAGS en el período.");
        }
    },
    cargar_anterior: async function(){
        if(this.data.pagina > 1){
            this.obtener_lista_tags(this.data.pagina - 1);
        }else{
            this.$refs.mensajeUsuarioSalir.mostrar("No hay página anterior.");
        }
    },
    cerrar: function () {
      this.dialogEvalListaGasto = false;
    },
    SalirSistema: function () {
      this.$router.replace("/");
    },
  },
};
</script>

<style>
</style>