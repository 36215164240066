          <template>
  <v-card
    :class="'mx-auto'"
    :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
    elevation-6
  >
    <v-card-title :style="'color: ' + paletColor.buttons.cancel.text + ';'">
      {{ formatoMonedaChile(tag.valor) }}<br />
    </v-card-title>
    <v-card-subtitle
      :style="'background-color: ' + paletColor.colorNeutro + ';'"
    >
      Autopista: {{ tag.autopista_nombre }}
    </v-card-subtitle>
    <v-card-text
      class="text-caption"
      :style="'background-color: ' + (tag.fueradeturno=='FUERA DE TURNO'?'#FFE8E8':tag.fueradeturno=='FUERA DE CARRERA'?'#FFF1C0':'#E6FFEE') + ';'"
    >
      <v-row dense>
        <v-col cols="12">
          Fecha {{ tag.fechahora }}
        </v-col>
        <v-col cols="6">portico:</v-col>
        <v-col cols="6" class="text-right"
          >{{ tag.portico }}</v-col
        >
        <v-col cols="6">Tipo:</v-col>
        <v-col cols="6" class="text-right">{{ tag.fueradeturno }}</v-col>
        <v-col cols="6">Periodo</v-col>
        <v-col cols="6" class="text-right">{{
          tag.periodo
        }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
          
          <script>
          
import {
  paletColor,
  isMobile,
  formatoMonedaChile,
} from "@/helpers/helpers";
import cmp_card_tag from "@/components/tag/card_tag.vue";
export default {
    data:()=>({
        paletColor: paletColor,
        formatoMonedaChile: formatoMonedaChile,
        isMobile: isMobile,
    }),
    props: {
        tag: Object,
    }
};
</script>
          
          <style>
</style>