<template>
  <v-container :class="isMobile ? 'px-1 py-1' : ''">
    <v-app-bar class="valores" height="80">
      <v-row class="d-flex align-center w-100 px-4" v-if="data">
        <v-col class="col-4 d-flex justify-center">
          <span class="text-end text-medium">
            <div>Viajes</div>
            Pend. {{ formatoMonedaChile(data.Pend) }}<br />
            Term. {{ formatoMonedaChile(data.Terminado) }}
          </span>
        </v-col>
        <!-- Columna central -->
        <v-col class="col-4 d-flex justify-center">
          <span class="text-end text-gasto">
            <div>Gasto</div>
            Serv. {{ formatoMonedaChile(data.comision) }} <br />
            Oper.
            {{ formatoMonedaChile(data.GastoCombustible + data.GastoTag) }}
            <br />
            Otro {{ formatoMonedaChile(data.GastoOtros) }}
          </span>
        </v-col>

        <!-- Columna derecha -->
        <v-col class="col-4 d-flex justify-end">
          <span
            class="text-end text-large font-weight-bold"
            :style="data.Utilidad < 0 ? 'color: #5e0b0b;' : 'color: #39723e;'"
            >{{ formatoMonedaChile(data.Utilidad) }}<br />Utilidad</span
          >
        </v-col>
      </v-row>
    </v-app-bar>

    <div v-if="data" class="pa-4" style="margin-top: 80px">
      <v-row dense>
        <v-col cols="8">
          <frm_text
            v-model="numeroVale"
            labelSet="Número de vale"
            MensajeAyudaSet="Ingrese el número de vale que desea buscar"
            iconoSet="mdi-text-box-check-outline"
            @keypress="buscarValePress"
            @click:clear="buscarVale"
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            density="compact"
            icon="mdi-magnify"
            @click="buscarValeClick"
          ></v-btn>
        </v-col>
      </v-row>
      <v-row dense v-if="data.items">
        <v-col
          cols="12"
          md="4"
          v-for="(carrera, index) in items_carrera"
          :key="index"
        >
          <card_carrera
            :carrera="carrera"
            :index="index"
            :realizaAcciones="index == 0"
            @recargarStart="buscarVale"
            @modificarCarrera="modificarCarrera"
            @cerrarCarrera="cerrarCarrera"
          />
        </v-col>
        <v-col cols="12" v-if="data.next">
          <buttonCancelform @accion="buscarVale" labelSet="Buscar más..."/>
        </v-col>
      </v-row>
      <v-row dense gutters class="text-caption">
        <v-col cols="12" md="6">
          <b>- Viajes Pend: </b>Son los viajes que te han asignado, pero que aún
          no has cerrado. No se consideran como ingreso<br />
          <b>- Viajes Term: </b>Son los viajes asignados que ya has cerrado.
          Estos se usan para calcular tus ingresos<br />
        </v-col>
        <v-col cols="12" md="6">
          <b>- Gasto Serv: </b>Es la comisión que cobra cada servicio.<br />
          <b>- Gasto Oper: </b>Son los gastos generados por conceptos como
          combustible, TAG y otros gastos necesarios para operar.<br />
          <b>- Gasto Otro: </b>Cualquier gasto adicional que no se considere
          operativo.<br />
        </v-col>
        <v-col cols="12">
          <b>- Utilidad: </b>Es el ingreso generado por los viajes, menos los
          gastos.<br />
        </v-col>
      </v-row>
    </div>
    <v-bottom-navigation>
      <v-btn @click="nuevaCarrera">
        <v-icon>mdi-sign-direction-plus</v-icon>
        <span style="font-size: 10px">Viaje</span>
      </v-btn>
      <v-btn @click="NuevoCombustible">
        <v-icon>mdi-water-plus</v-icon>
        <span style="font-size: 10px">Combustible</span>
      </v-btn>
      <v-btn @click="NuevoGasto">
        <v-icon>mdi-cash-plus</v-icon>
        <span style="font-size: 10px">Gasto</span>
      </v-btn>
      <v-btn @click="DashBoard">
        <v-icon>mdi-view-dashboard</v-icon>
        <span style="font-size: 10px">Mis ingresos</span>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="SalirSistema" />
</template>

<script>
import {
  obtenerJsonGET,
  formatoFechaHoraTXT,
  formatoMonedaChile,
  paletColor,
  isMobile,
} from "@/helpers/helpers";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import card_carrera from "@/components/carrera/vistaCardListaCarrera.vue";
import frm_text from "@/components/form/textos.vue";

export default {
  data: () => ({
    isMobile: isMobile(),
    localdialogveruta: false,
    legsCarrera: null,
    numeroVale: '',
    tabs: null,
    data: null,
    items_carrera: [],
    paletColor: paletColor,
    next: 1,
    formatoMonedaChile: formatoMonedaChile
  }),
  components: {
    buttonCancelform,
    mensajeUsuario,
    card_carrera,
    frm_text,
  },
  mounted: async function () {
    this.setupAudio();
    this.buscarVale();
  },
  methods: {
    // Prepara y precarga el audio
    setupAudio: async function() {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const audioUrl = require('@/sound/simple-notification-152054.mp3'); // Ruta al archivo de audio
      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        console.log('Audio precargado exitosamente.');
      } catch (error) {
        console.error('Error al precargar el audio:', error);
      }
    },
    playSound: function() {
      if (this.audioContext && this.audioBuffer) {
        const source = this.audioContext.createBufferSource();
        source.buffer = this.audioBuffer;
        source.connect(this.audioContext.destination);
        source.start();
      } else {
        console.error('El audio no está preparado.');
      }
    },
    buscarValePress: async function(event){
      if(event.keyCode == 13 && this.numeroVale.length>0){
        this.buscarVale();
      }
    },
    buscarValeClick: async function(){
      if(this.numeroVale){
        if (this.numeroVale.length>0) {
          this.buscarVale();
        }
      }
    },
    buscarVale: async function () {
      let param = null;
      param = {
          page: this.next,
        };
      console.log("this.numeroVale",this.numeroVale);
      if (this.numeroVale) {
        param.numeroVale= this.numeroVale;
      }
      let data = await obtenerJsonGET("/carrera/listacarreraconductorForConductor/",param);

      if (data.error == 0) {
        this.data = data.data;
        this.next = this.data.next;
        this.items_carrera = this.items_carrera.concat(this.data.items);
        this.playSound(); // Emite el sonido automáticamente
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    goToHome() {
      // Lógica para navegar a Home
    },
    goToSearch() {
      // Lógica para navegar a Búsqueda
    },
    goToSettings() {
      // Lógica para navegar a Configuración
    },

    modificarCarrera: function (carrera) {
      sessionStorage.setItem("carrera", JSON.stringify(carrera));
      this.$router.push("/modificaCarrera");
    },
    cerrarCarrera: function (carrera) {
      sessionStorage.setItem("carrera", JSON.stringify(carrera));
      this.$router.push("/cerrarCarrera");
    },
    listaCarrera: function () {
      this.$router.push("/start");
    },
    nuevaCarrera: function () {
      this.$router.push("/registraNuevaCarrera");
    },
    NuevoCombustible: function () {
      this.$router.push("/nuevocombustible");
    },
    NuevoGasto: function () {
      this.$router.push("/nuevogasto");
    },
    DashBoard: function () {
      this.$router.push("/dashboard");
    },
    SalirSistema: function () {
      this.$router.replace("/");
    },
  },
};
</script>

<style>
/* Opcional: ajuste de alturas del contenido para asegurar que no sobreponga los menús */
.v-main {
  padding-top: 64px; /* Altura del v-app-bar */
  padding-bottom: 56px; /* Altura del v-bottom-navigation */
}
/* Efecto de degradado y sombra para la v-app-bar */
.valores {
  background: linear-gradient(
    135deg,
    #f3e5f5,
    #e1bee7
  ); /* Degradado suave en tonos pastel */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave para efecto elevado */
}

/* Ajustes de estilo para cada columna */
.text-medium {
  font-size: 11px;
  color: #273661; /* Color de texto destacado */
}
.text-gasto {
  font-size: 11px;
  color: #5e0b0b; /* Color de texto destacado */
}

.text-large {
  font-size: 18px;
  color: #27612c; /* Color de texto destacado */
}
</style>