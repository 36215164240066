<template>
  <div>
    <div id="map"></div>
    <v-btn class="floating-btn" color="primary" fab v-if="activa_boton_centrar" @click="centrar_mapa">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { ref } from "vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker"; // Importa la librería leaflet-rotatedmarker
import polyline from "@mapbox/polyline";
import { determineDirection, formatoFechaHoraVue } from "@/helpers/helpers";

export default {
  name: "RouteMapGo",
  data: () => ({
    p: null,
    map: null,
    points: [],

    routePolylineDecoded: null,
    progressPolylineDecoded: null,
    zoom_estandard: 16,
    activa_boton_centrar: false,

    userMarker: null,

    actual_lat: -33.44627,
    actual_lng: -70.661128,

    carIcon: L.icon({
      iconUrl: "/img/iconos/AutoRojo.png",
      iconSize: [32, 32],
      iconAnchor: [16, 16],
    }),

    routeLayer: [],
    routeTrayectoria: [],
    markers: [], // Guardará todos los marcadores para fácil manejo
    currentLocation: null,
    currentLocationMarker: null,
    trayectoria: [],
    formatoFechaHoraVue: formatoFechaHoraVue,

    clickInterval: null,
  }),
  props: {
    polyline: {
      type: String,
      required: true,
    },
    recorrido: {
      type: String,
      required: true,
    },
    rutas: {
      type: Array,
      required: true,
    },
    destinations: {
      type: Array,
      required: true,
    },
    routeColor: {
      type: String,
      default: "#2ac9f8", // Verde por defecto
    },
    avanceColor: {
      type: String,
      default: "#3c5b40", // Azul por defecto
    },
  },
  components: {},
  mounted: function () {
    this.$nextTick(() => {
      this.initializeMap();
    });
    this.startClickSimulation();
    // Detener simulación al salir de la página
    window.addEventListener("beforeunload", this.stopClickSimulation);
  },
  beforeDestroy() {
    this.stopClickSimulation();
    window.removeEventListener("beforeunload", this.stopClickSimulation);
  },
  methods: {
    initializeMap: function () {
      this.decodePolylines();

      if (this.progressPolylineDecoded) {
        if (this.progressPolylineDecoded.length > 0) {
          this.actual_lat =
            this.progressPolylineDecoded[
              this.progressPolylineDecoded.length - 1
            ][0];
          this.actual_lng =
            this.progressPolylineDecoded[
              this.progressPolylineDecoded.length - 1
            ][1];
        }
      }
      if (this.actual_lat == 0) {
        if (this.routePolylineDecoded) {
          if (this.routePolylineDecoded.length > 0) {
            this.actual_lat =
              this.routePolylineDecoded[0
              ][0];
            this.actual_lng =
              this.routePolylineDecoded[0
              ][1];
          }
        }
      }

      if (this.map) {
        console.warn("El mapa ya está inicializado, destruyéndolo...");
        this.map.remove(); // Elimina el mapa existente si ya fue creado
      }

      const mapContainer = document.getElementById("map");
      if (!mapContainer) {
        console.error("No se encontró el contenedor del mapa.");
        return;
      }

      // Inicializa el mapa
      this.map = L.map("map").setView([this.actual_lat, this.actual_lng], this.zoom_estandard);
      this.map.zoom_estandard = this.zoom_estandard;

      // Agregar capa base
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: this.zoom_estandard,
        attribution: '&copy; <a href="https://katal.cl">Katal</a>',
      }).addTo(this.map);

      this.userMarker = L.marker([this.actual_lat, this.actual_lng], {
        icon: this.carIcon,
        rotationAngle: 0, // Ángulo inicial
        rotationOrigin: "center", // Punto de rotación
      }).addTo(this.map);

      this.map.setView([this.actual_lat, this.actual_lng], this.zoom_estandard, { animate: true });

      this.map.on('zoomend', this.activarZoom);
      this.drawRoute();
      this.drawAvance(false);

      this.addDestinations();
      this.centrar_mapa(0,this.zoom_estandard);
      this.setupRealTimeTracking();
    },
    activarZoom: function(e) {

        if(e.target.getZoom() == e.target.zoom_estandard){
          this.activa_boton_centrar = false;
        }else{
          this.activa_boton_centrar = true;
        }
    },

    decodePolylines() {
      let routePolyline = !this.polyline
        ? ""
        : typeof this.polyline === "object"
        ? JSON.stringify(this.polyline)
        : String(this.polyline);
      routePolyline = routePolyline.replace("[@REY]", "\\");

      let progressPolyline = !this.recorrido
        ? ""
        : typeof this.recorrido === "object"
        ? JSON.stringify(this.recorrido)
        : String(this.recorrido);
      progressPolyline = progressPolyline.replace("[@REY]", "\\");

      // Convertir las rutas en polilíneas decodificadas
      this.routePolylineDecoded = !routePolyline
        ? null
        : polyline.decode(routePolyline);
      this.progressPolylineDecoded = !progressPolyline
        ? null
        : polyline.decode(progressPolyline);

    },

    drawRoute: function () {
      // Eliminar capa de ruta anterior si existe
      if (this.routeLayer.length == 1) {
        this.map.removeLayer(this.routeLayer[0]);
      }

      if (this.routePolylineDecoded) {
        this.routeLayer = this.routeLayer || []; // Inicializa si no existe

        if (this.routePolylineDecoded.length > 0) {
          // Dibujar la nueva ruta
          const newRouteLayer = L.polyline(this.routePolylineDecoded, {
            color: this.routeColor,
            weight: 10,
            opacity: 1,
          }).addTo(this.map);

          this.routeLayer.push(newRouteLayer);
        }
      }
    },
    drawAvance: function (update) {
      // Eliminar capa de ruta anterior si existe
      if (this.routeLayer.length == 2) {
        this.map.removeLayer(this.routeLayer[1]);
        this.routeLayer = [this.routeLayer[0]];
      }

      if (this.routeLayer) {
        this.routeLayer = this.routeLayer || []; // Inicializa si no existe

        if (this.progressPolylineDecoded) {
          if (this.progressPolylineDecoded.length > 1) {
            // Dibujar la nueva ruta
            const newRouteLayer = L.polyline(this.progressPolylineDecoded, {
              color: this.avanceColor,
              weight: 4,
              opacity: 1,
            }).addTo(this.map);
            if (update) {
              this.p = polyline.encode(this.progressPolylineDecoded);
              this.$emit("updatePolylineReal", this.p);
            }
            this.routeLayer.push(newRouteLayer);
          }
        }
      }
    },
    addDestinations: function () {
      if (!this.map) {
        console.warn("El mapa no está inicializado.");
        return;
      }

      if (this.rutas) {
        if (this.rutas.length > 0) {
          const origenIcon = L.icon({
            iconUrl: "/img/iconos/IconoMapaLocationsInicio.gif",
            iconSize: [23, 32],
            iconAnchor: [11, 32],
          });
          const destinoIcon = L.icon({
            iconUrl: "/img/iconos/IconoMapaLocationsFinal.gif",
            iconSize: [23, 32],
            iconAnchor: [11, 32],
          });
          this.markers.forEach((marker) => this.map.removeLayer(marker));

          this.rutas.forEach((location, index) => {
            if (index == 0) {
              const origenMarker = L.marker(
                [location.origen.lat, location.origen.lng],
                {
                  icon: origenIcon,
                }
              ).addTo(this.map);
              this.markers.push(origenMarker);
            }

            const destinoMarker = L.marker(
              [location.destino.lat, location.destino.lng],
              {
                icon: destinoIcon,
              }
            ).addTo(this.map);
            this.markers.push(destinoMarker);
          });
        }
      }
    },

    setupRealTimeTracking() {
      // Simular la ubicación en tiempo real
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(
          (position) => {
            if (this.map) {
              this.actual_lat = position.coords.latitude;
              this.actual_lng = position.coords.longitude;

              if (!this.userMarker) {
                this.userMarker = L.marker([lat, lng], {
                  icon: this.carIcon,
                  rotationAngle: 0, // Ángulo inicial
                  rotationOrigin: "center", // Punto de rotación
                }).addTo(this.map);
              } else {
                this.userMarker.setLatLng([this.actual_lat, this.actual_lng]);
              }

              let ubicacion = [this.actual_lat, this.actual_lng];

              if (!this.progressPolylineDecoded) {
                this.progressPolylineDecoded = [];
              }
              if (this.progressPolylineDecoded.length > 0) {
                if (
                  this.progressPolylineDecoded[
                    this.progressPolylineDecoded.length - 1
                  ][0] != this.actual_lat &&
                  this.progressPolylineDecoded[
                    this.progressPolylineDecoded.length - 1
                  ][1] != this.actual_lng
                ) {
                  this.progressPolylineDecoded.push(ubicacion);
                  this.drawAvance(true);
                }
              } else {
                this.progressPolylineDecoded.push(ubicacion);
              }

              // Actualizar la ubicación en el mapa
              //this.map.setView(ubicacion, this.map.getZoom(), { animate: true });
              this.updateMapOrientation();
            }
          },
          (error) => {
            console.error("Error obteniendo la ubicación: ", error);
            alert("No se pudo obtener la ubicación.");
          },
          { enableHighAccuracy: true }
        );
      } else {
        alert("La geolocalización no es compatible con este navegador.");
      }
    },
    updateMapOrientation() {
      let lat1 = null;
      let lng1 = null;

      let lat2 = null;
      let lng2 = null;

      let direction = "";
      if (this.progressPolylineDecoded) {
        if (this.progressPolylineDecoded.length > 1) {
          lat1 =
            this.progressPolylineDecoded[
              this.progressPolylineDecoded.length - 2
            ][0];
          lng1 =
            this.progressPolylineDecoded[
              this.progressPolylineDecoded.length - 2
            ][1];

          lat2 =
            this.progressPolylineDecoded[
              this.progressPolylineDecoded.length - 1
            ][0];
          lng2 =
            this.progressPolylineDecoded[
              this.progressPolylineDecoded.length - 1
            ][1];
        } else if (this.routePolylineDecoded.length > 0) {
          lat1 = this.routePolylineDecoded[0][0];
          lng1 = this.routePolylineDecoded[0][1];

          lat2 = this.routePolylineDecoded[1][0];
          lng2 = this.routePolylineDecoded[1][1];
        }
      } else if (this.routePolylineDecoded) {
        if (this.routePolylineDecoded.length > 0) {
          lat1 = this.routePolylineDecoded[0][0];
          lng1 = this.routePolylineDecoded[0][1];

          lat2 = this.routePolylineDecoded[1][0];
          lng2 = this.routePolylineDecoded[1][1];
        }
      }

      let azimuth = 0;
      if (lat1) {
        // Calculando la dirección con azimut entre dos coordenadas
        const deltaLng = lng2 - lng1;
        const deltaLat = lat2 - lat1;

        // Azimut entre las dos coordenadas en grados
        azimuth = Math.atan2(deltaLng, deltaLat) * (180 / Math.PI);

        // Determinamos la dirección basada en el ángulo de azimut
        if (azimuth > -22.5 && azimuth <= 22.5) {
          direction = "Este"; // Entre -22.5 y 22.5 grados
        } else if (azimuth > 22.5 && azimuth <= 67.5) {
          direction = "Noreste"; // Entre 22.5 y 67.5 grados
        } else if (azimuth > 67.5 && azimuth <= 112.5) {
          direction = "Norte"; // Entre 67.5 y 112.5 grados
        } else if (azimuth > 112.5 && azimuth <= 157.5) {
          direction = "Noroeste"; // Entre 112.5 y 157.5 grados
        } else if (azimuth > 157.5 || azimuth <= -157.5) {
          direction = "Oeste"; // Entre 157.5 y -180 grados (y de -180 a -157.5)
        } else if (azimuth > -157.5 && azimuth <= -112.5) {
          direction = "Suroeste"; // Entre -157.5 y -112.5 grados
        } else if (azimuth > -112.5 && azimuth <= -67.5) {
          direction = "Sur"; // Entre -112.5 y -67.5 grados
        } else if (azimuth > -67.5 && azimuth <= -22.5) {
          direction = "Sureste"; // Entre -67.5 y -22.5 grados
        }

        // Aquí se puede usar el 'azimuth' para ajustar la orientación del mapa (dependiendo de la librería de rotación)

        this.adjustMapView(azimuth,this.map.getZoom()==this.zoom_estandard?this.zoom_estandard:0);
      }
      return direction;
    },

    centrar_mapa:  function(){
      this.adjustMapView(0,this.zoom_estandard);
    },

    adjustMapView(azimuth = 0,zoom = 0) {
      if (!this.map) return;

      // Dirigir el mapa hacia las nuevas coordenadas (lat, lng)

      if (zoom > 0) {
        this.map.setView([this.actual_lat, this.actual_lng], this.zoom_estandard);
      }

      // Si deseas rotar el mapa según la dirección (con una librería como leaflet-rotatedmarker)
      // Aquí rotamos el marcador de acuerdo con el azimut calculado
      if (this.userMarker && azimuth != 0) {
        this.userMarker.setRotationAngle(azimuth); // Si se usa un marcador rotado
      }
    },

    simulateClick() {
      const clickEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      const element = document.body;
      element.dispatchEvent(clickEvent);
      console.log("Simulación de clic realizada");
    },
    startClickSimulation() {
      this.clickInterval = setInterval(this.simulateClick, 30000); // Cada 30 segundos
    },
    stopClickSimulation() {
      if (this.clickInterval) {
        clearInterval(this.clickInterval);
        this.clickInterval = null;
      }
    },
  },
};
</script>

<style>
#map {
  border: 1px solid #ccc;
  border-radius: 15px;
  height: 72vh; /* Por ejemplo, ocupar toda la pantalla */
  width: 100%; /* Ancho completo */
}
.leaflet-control-attribution {
  display: none !important;
}
</style>