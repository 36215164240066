<template>
  <v-card
    :class="
      'bg-' +
      paletColor.carrera_estado[carrera.carrera_estado_id] +
      ' rounded-xl'
    "
  >
    <v-card-title>
      <v-row
        dense
        no-gutters
        :style="
          'font-size:' +
          sizeText.card.lista.destacado.size +
          'px;font-weight: bold;'
        "
      >
        <v-col cols="4">{{ carrera.comprobante }}</v-col>
        <v-col cols="4" align="center">{{
          carrera.kilometros + " km" + (carrera.kilometros > 1 ? "s" : "") + "."
        }}</v-col>
        <v-col cols="4" align="right">
          <span v-if="carrera.duracion_horas > 0">{{
            carrera.duracion_horas +
            "Hr" +
            (carrera.duracion_horas > 1 ? "s" : "")
          }}</span>
          {{
            carrera.duracion_minutos +
            "Min" +
            (carrera.duracion_minutos > 1 ? "s" : "")
          }}
        </v-col>
        <v-col cols="4">{{ carrera.tag }} TAGs</v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <v-row dense no-gutters>
        <v-col cols="6" align="center"
          >Estado: <br />{{ carrera.estado_descripcion }}</v-col
        >
        <v-col cols="6" align="center">{{
          formatoFechaHoraTXT(carrera.fechahora, false)
        }}</v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text dense class="text-caption">
      <v-row dense no-gutters>
        <v-col
          cols="6"
          :style="
            'font-size:' +
            sizeText.card.lista.destacado.size +
            'px;font-weight: bold;'
          "
          align="center"
        >
          <span
            v-if="
              carrera.valor_total != carrera.valor_calculado &&
              carrera.valor_calculado
            "
            class="text-caption"
          >
            Calculado: {{ formatoMonedaChile(carrera.valor_calculado) }}<br />
          </span>
          <span
            v-if="
              carrera.valor_total != carrera.valor_conductor &&
              carrera.valor_conductor
            "
            class="text-caption"
          >
            Conductor: {{ formatoMonedaChile(carrera.valor_conductor) }}<br />
          </span>
          <span
            v-if="
              carrera.valor_total != carrera.valor_conductor &&
              carrera.valor_servicio
            "
            class="text-caption"
          >
            Servicio S/Revisar: {{ formatoMonedaChile(carrera.valor_servicio)
            }}<br />
          </span>
          <span
            v-if="
              carrera.valor_total != carrera.valor_conductor &&
              carrera.valor_total
            "
            :class="'text-caption '+(carrera.valor_total != carrera.valor_conductor
                ? 'bg-' + paletColor.carrera_estado[1510]
                : '')
            "
          >
            Probable pago: {{ formatoMonedaChile(carrera.valor_total) }}<br />
          </span>
          <span
          >
            {{ formatoMonedaChile(carrera.valor_total) }}
          </span>
        </v-col>
        <v-col cols="6" align="center"
          >{{ carrera.servicio_nombre }}<br />
          {{ carrera.destinos }} destino{{
            parseInt(carrera.destinos) > 1 ? "s" : ""
          }}</v-col
        >
        <v-col dense cols="12" no-gutters v-if="realizaAcciones">
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 60"
            @izquierda="rechazarCarrera"
            @derecha="aceptarCarrera"
          />
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 100"
            @izquierda="atrasado"
            textoIzquierda="Atrasado"
            @derecha="enCamino"
            textoDerecha="En Camino"
          />
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 110"
            @izquierda="eliminar"
            textoIzquierda="Eliminar"
            @derecha="enTransito"
            textoDerecha="Iniciar Viaje"
          />
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 500"
            @izquierda="rechazarCarrera"
            textoIzquierda="Rechazar"
            @derecha="enCamino"
            textoDerecha="En Camino"
          />
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 510"
            @izquierda="atrasado"
            textoIzquierda="Atrasado"
            @derecha="enPosicion"
            textoDerecha="En Posición"
          />
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 520"
            @izquierda="rechazarCarrera"
            textoIzquierda="Rechazar"
            @derecha="enTransito"
            textoDerecha="Iniciar Viaje"
          />
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 1000"
            @izquierda="incidente"
            textoIzquierda="Incidente"
            @derecha="enTransito"
            textoDerecha="Continuar Viaje"
          />
          <btn_aceptar
            v-if="parseInt(carrera.carrera_estado_id) == 1010"
            @izquierda="rechazarCarrera"
            textoIzquierda="Rechazar"
            @derecha="enTransito"
            textoDerecha="Iniciar Viaje"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-action>
      <v-spacer />
      <v-row class="pa-2" dense>
        <v-col align="center" v-if="parseInt(carrera.carrera_estado_id) == 110">
          <v-btn
            density="compact"
            icon="mdi-pencil-outline"
            @click="modificarCarrera(carrera)"
          ></v-btn>
        </v-col>
        <v-col align="center">
          <v-btn
            density="compact"
            icon="mdi-map-marker-right-outline"
            @click="vermapa(carrera)"
          ></v-btn>
        </v-col>
      </v-row>
    </v-card-action>
  </v-card>
  <ver_mapa_carrera ref="ver_mapa_carrera" />
  <iniciar_viaje_carrera2
    ref="iniciar_viaje_carrera2"
    :rutas="carrera.legs"
    @enDestino="enDestino"
    @eliminar="eliminar"
    @updatePolylineReal="updatePolylineReal"
  />
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="salirApp" />
</template>

<script>
import {
  formatoFechaHoraTXT,
  formatoMonedaChile,
  isMobile,
  sizeText,
  enviarJsonPOST,
  paletColor,
} from "@/helpers/helpers";
import ver_mapa_carrera from "@/components/carrera/ver_mapa_carrera.vue";
import btn_aceptar from "@/components/form/buttonDeslizar.vue";
import iniciar_viaje_carrera2 from "@/components/carrera/movimiento_ruta_carrera.vue";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";

export default {
  data: () => ({
    formatoFechaHoraTXT: formatoFechaHoraTXT,
    formatoMonedaChile: formatoMonedaChile,
    sizeText: sizeText,
    color_card: null,
    paletColor: paletColor,
  }),
  props: {
    carrera: Object,
    index: Number,
    realizaAcciones: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ver_mapa_carrera,
    btn_aceptar,
    iniciar_viaje_carrera2,
    mensajeUsuario,
  },
  mounted: function () {
    console.log(
      "this.carrera.carrera_estado_id",
      this.carrera.carrera_estado_id
    );
    this.cambiarColor();
  },
  methods: {
    eliminar: function () {
      alert("eliminar en construcción");
    },
    rechazarCarrera: function () {
      alert("rechazar carrera en construcción");
    },
    aceptarCarrera: function () {
      alert("aceptar carrera en construcción");
    },
    atrasado: function () {
      alert("atrasado en construcción");
    },
    enCamino: function () {
      alert("en camino en construcción");
    },
    enPosicion: function () {
      alert("en posición en construcción");
    },
    enTransito: async function () {
      let fd = new FormData();
      fd.append("carrera_uuid", this.carrera.id);
      let data = await enviarJsonPOST("/carrera/inicarviajecoductor/", fd);

      if (data) {
        if (data.error == 0) {
          console.log("data.data.ruta", data.data.ruta);
          this.carrera.carrera_estado_id = data.data.carrera_estado_id;
          this.carrera.estado_descripcion = data.data.estado_descripcion;
          this.$refs.iniciar_viaje_carrera2.carrera = this.carrera;
          this.$refs.iniciar_viaje_carrera2.recorrido = data.data.recorrido;
          this.$refs.iniciar_viaje_carrera2.polyline = data.data.ruta;
          this.$refs.iniciar_viaje_carrera2.localdialogverutamapa = true;
          this.cambiarColor();
        } else if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Servicio no responde. el sistema requerirá que vuelva a autenticarse."
        );
      }
    },
    updatePolylineReal: async function (poly) {
      let fd = new FormData();
      fd.append("carrera_uuid", this.carrera.id);
      fd.append("poly", poly);
      let data = await enviarJsonPOST(
        "/carrera/actualizaViajeRealConductor/",
        fd
      );
    },
    incidente: function () {
      alert("con incidente en construcción");
    },
    enDestino: async function () {
      this.$emit("cerrarCarrera", this.carrera);
    },
    modificarCarrera: function (carrera) {
      this.$emit("modificarCarrera", carrera);
    },
    cerrarCarrera: function (carrera) {
      this.$emit("cerrarCarrera", carrera);
    },

    vermapa: function (carrera) {
      console.log("carrera.polyline", carrera.polyline);
      this.$refs.ver_mapa_carrera.processRouteData2(
        carrera.polyline,
        carrera.legs,
        carrera.kilometros
      );
    },
    cambiarColor: function () {
      if (this.carrera.carrera_estado_id == 110) {
        this.color_card = "bg-cyan-lighten-4";
      } else if (this.carrera.carrera_estado_id < 1020) {
        this.color_card = "bg-teal-lighten-4";
      } else if (this.carrera.carrera_estado_id >= 1020) {
        this.color_card = "bg-light-blue-lighten-4";
      } else {
        this.color_card = "bg-teal-lighten-4";
      }
    },
    salirApp: function () {
      this.$router.replace("/");
    },
  },
};
</script>

<style>
</style>