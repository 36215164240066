<template>
  <v-app-bar
    app
    flat
    :style="'background-color: ' + paletColor.colorPrincipal + ';'"
  >
    <!-- Botón para el menú lateral -->
    <v-app-bar-nav-icon
      @click="toggleDrawer"
      :style="'color: ' + paletColor.buttons.cancel.text + ';'"
    ></v-app-bar-nav-icon>

    <!-- Imagen del logo alineada a la izquierda -->
    <v-img :src="obtenerLogoApp()" alt="Logo" height="50" class="mx-3"></v-img>
    <span
      :style="'color: ' + paletColor.buttons.cancel.text + ';'"
      class="text-caption"
      >Conductor</span
    >

    <!-- Espacio para empujar el botón de cerrar sesión a la derecha -->
    <v-spacer></v-spacer>

    <!-- Botón de cerrar sesión con ícono -->
    <div
      v-html="formattedNombre()"
      :style="'color: ' + paletColor.buttons.cancel.text + ';font-size: 13px;'"
    ></div>
    <v-btn
      icon
      @click="logout"
      :style="'color: ' + paletColor.buttons.cancel.text + ';'"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>

  <!-- Menú lateral que se despliega -->
  <v-navigation-drawer
    v-model="drawer"
    app
    temporary
    :style="'background-color: ' + paletColor.colorFondoAlternativo + ';'"
  >
    <div v-if="usuario" class="pt-4 pb-4">
      <v-icon>mdi-account-tie</v-icon>
      {{ usuario.nombre }}
    </div>
    <v-divider />
    <v-list dense>
      <v-list-item-group dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.text"
          @click="goPage(item.url)"
        >
          <v-list-item-content class="d-flex align-center">
            <!-- Ícono y texto en la misma línea -->
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title class="ml-3">{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { paletColor, enviarJsonPOST, obtenerLogoApp } from "@/helpers/helpers";
export default {
  name: "menu_superior",

  data: () => ({
    usuario: JSON.parse(sessionStorage.getItem("usuarioDriverIn")),
    apiUrl: process.env.VUE_APP_API_URL,
    debugMode: process.env.VUE_APP_DEBUG_MODE === "true", // Convierte a booleano si es necesario
    drawer: false, // Estado del menú lateral
    paletColor: paletColor,
    menuItems: [
      { text: "Mis Viajes", icon: "mdi-home", url: "/start" },
      {
        text: "Nuevo Viaje",
        icon: "mdi-sign-direction-plus",
        url: "/registraNuevaCarrera",
      },
      {
        text: "Nueva carga combustible",
        icon: "mdi-water-plus",
        url: "/nuevocombustible",
      },
      {
        text: "Nuevo gasto",
        icon: "mdi-sign-direction-plus",
        url: "/nuevogasto",
      },
      { text: "Mis ingresos", icon: "mdi-view-dashboard", url: "/dashboard" },
      { text: "Mis servicios", icon: "mdi-face-agent", url: "/listaservicio" },
      //{ text: "Perfil", icon: "mdi-account", url: "/listacarreras" },
      //{ text: "Ajustes", icon: "mdi-cog", url: "https://www.google.cl" },
    ], // Opciones del menú lateral
    obtenerLogoApp: obtenerLogoApp,
    audioContext: null, // Contexto de audio para el Web Audio API
    audioBuffer: null, // Buffer para almacenar el sonido precargado
    isAudioUnlocked: false, // Controla si el audio está desbloqueado
  }),
  components: {},
  mounted() {
    this.setupAudio();
    this.unlockAudio(); // Intenta desbloquear el contexto de audio
    const frec1 = process.env.VUE_APP_SEGUNDOS_REGISTRA_UBICACION;
    const frecuencia = parseFloat(frec1) * 1000;
    setInterval(this.gatillarFuncionesRevisionAlertas, frecuencia);
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer; // Alternar el estado del menú lateral
    },
    logout() {
      // Función para cerrar sesión
      sessionStorage.removeItem("usuarioDriverIn"); // Elimina la sesión
      this.$router.replace("/");
    },
    goPage: function (page) {
      if (page == "/") {
        window.location.replace("/");
      } else {
        this.$router.push(page);
      }
    },
    formattedNombre() {
      // Separar el texto por espacios y convertirlo en un arreglo
      let  formattedString = null;
      if(this.usuario){
        const words = this.usuario.nombre.split(" ");

        // Tomar los dos primeros elementos y unirlos con un <br>
        formattedString = words.slice(0, 2).join("<br>");
      }
      return formattedString;
    },
    gatillarFuncionesRevisionAlertas: async function () {
      //await this.playSound();
      await this.enviarGeoLocalizacion();
    },
    enviarGeoLocalizacion: async function () {
      if ("geolocation" in navigator) {
        // Obtén la ubicación
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Datos de ubicación obtenidos
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            let posi = {
              lat: lat,
              lng: lng,
            };
            let fd = new FormData();
            fd.append("lat", lat);
            fd.append("lng", lng);
            let data = enviarJsonPOST("/servicio/registraubicacion/", fd);
          },
          (error) => {
            return null;
            console.error("Error obteniendo ubicación:", error.message);
          }
        );
      } else {
        return null;
        console.error("Geolocalización no está disponible en este navegador.");
      }
    },

    // Prepara y precarga el audio
    setupAudio: async function () {
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioUrl = require("@/sound/simple-notification-152054.mp3"); // Ruta al archivo de audio
      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        console.log("Audio precargado exitosamente.");
      } catch (error) {
        console.error("Error al precargar el audio:", error);
      }
    },
    playSound: async function () {
      console.log("intenta sonar");
      if (this.isAudioUnlocked && this.audioContext && this.audioBuffer) {
        this.audioBuffer.muted = false;
        const source = this.audioContext.createBufferSource();
        source.buffer = this.audioBuffer;
        source.connect(this.audioContext.destination);
        //source.start();
        const simulateClick = new Event("click");
        //document.dispatchEvent(simulateClick);
      } else {
        console.error("El audio no está preparado.");
      }
    },
    unlockAudio: function () {
      const unlock = () => {
        if (this.audioContext && this.audioContext.state === "suspended") {
          this.audioContext.resume().then(() => {
            console.log("Contexto de audio desbloqueado.");
            this.isAudioUnlocked = true;

            // Simula la interacción reproduciendo un sonido en silencio
            const source = this.audioContext.createBufferSource();
            source.buffer = this.audioContext.createBuffer(1, 1, 22050); // Crea un buffer vacío
            source.connect(this.audioContext.destination);
            //source.start(0);

            // Elimina el listener después de desbloquear
            window.removeEventListener("click", unlock);
            window.removeEventListener("touchstart", unlock);
          });
        }
      };

      // Escucha el primer clic o toque del usuario para desbloquear el audio
      window.addEventListener("click", unlock, { once: true });
      window.addEventListener("touchstart", unlock, { once: true });
    },
  },
};
</script>

<style>
.app-bar {
  background-color: #2c3e50; /* Color del app bar */
  color: #ecf0f1; /* Color del texto */
}
</style>