module.exports.paletColor = {
  textoGeneral: "#555555",
  card: {
    backgroundgral: "#2C3E50",
  },
  colorFondoAlternativo: "#EBEFF4",
  colorFondoAlternativo2: "#B9C8D9",
  colorPrincipal: "#2C3E50",
  colorSecundario: "#1ABC9C",
  colorDeAcento: "#E74C3C",
  colorTexto: "#34495E",
  colorNeutro: "#ECF0F1",
  buttons: {
    submit: {
      background: "#d26e4b",
      text: "#EBEFF4",
    },
    cancel: {
      background: "#446084",
      text: "#EBEFF4",
    },
    deslizar: {
      background: "#5E7D9C",
      text: "#EBEFF4",
    },
    problem: {
      background: "#f44336",
      text: "#EBEFF4",
    },
    
    siguiente: {
      background: "#006633",
      text: "#EBEFF4",
    },
  },
  carrera_estado: {
    [0]: 'bg-cyan-lighten-4', //creado
    [50]: 'teal-lighten-5', //Pendiente Asignación
    [60]: 'teal-lighten-4', //Pendiente Aceptación
    [70]: 'amber-lighten-3', //Rechazado
    [100]: 'teal-lighten-3', //Aceptado
    [110]: 'teal-lighten-3', //Ingreso Conductor
    [500]: 'orange-lighten-4', //Atrasado
    [510]: 'teal-accent-1', //En Camino
    [520]: 'teal-accent-2', //En posición
    [1000]: 'teal-accent-3', //En transito
    [1010]: 'orange-lighten-3', //Con incidente
    [1020]: 'green-accent-2', //Pasajero en destino
    [1500]: 'light-green-accent-4', //Aprobado
    [1510]: 'light-green-accent-1', //Aprobado con ajuste
    [1520]: 'red-lighten-3', //Rechazado
    [2000]: 'green-lighten-4', //Listo para Facturación cliente
    [2010]: 'green-lighten-3', //Facturado cliente
    [3000]: 'green-lighten-2', //Listo para facturación Vehículo
    [3010]: 'green-accent-4', //Pagado vehículo
  }
};

module.exports.sizeText = {
  card: {
    lista: {
      destacado: {
        size: 16,
      },
    },
  },
};

module.exports.determineDirection = function (coordinates) {
  if (!coordinates) {
    return null;
  }
  if (coordinates.length < 2) {
    return null;
  }

  const { lat1, lng1 } = coordinates[0]; // Coordenadas de origen
  const { lat2, lng2 } = coordinates[1]; // Coordenadas de destino

  let direction = "";

  // Determinar si es norte/sur
  if (lat2 > lat1) {
    direction += "Sur"; // La latitud del destino es mayor, vas hacia el sur
  } else if (lat2 < lat1) {
    direction += "Norte"; // La latitud del destino es menor, vas hacia el norte
  }

  // Determinar si es este/oeste
  if (lng2 > lng1) {
    direction += direction ? " y Este" : "Este"; // Longitud del destino es mayor, vas hacia el este
  } else if (lng2 < lng1) {
    direction += direction ? " y Oeste" : "Oeste"; // Longitud del destino es menor, vas hacia el oeste
  }

  // Si hay ambas direcciones, puede ser intermedio
  if (direction === "") {
    direction = "Mismo lugar"; // Si no hay cambio en latitud o longitud
  }

  return direction;
};
module.exports.isMobile = function () {
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile/i.test(
    navigator.userAgent
  );
};

module.exports.obtenerLogoApp = function () {
  let dom = module.exports.obtenerDominio();
  let logo = "/img/katal/LogoTextoBlanco.gif";
  if (dom.dominio == "katal.cl") {
    logo = "/img/katal/LogoTextoBlanco.gif";
  } else if (dom.dominio == "driverin.cl") {
    logo = "/img/driverin/LogoDriverInNombreBlanco.gif";
  }
  return logo;
};

module.exports.obtenerDominio = function () {
  let dom = {
    subdominio: null,
    dominio: null,
  };
  const dominioCompleto = window.location.hostname;
  const partesDominio = dominioCompleto.split(".");
  let dominioPrincipal = "";
  if (partesDominio.length > 2) {
    dom.subdominio = partesDominio.slice(0, -2).join("."); // Todo antes del dominio principal
    dom.dominio = partesDominio.slice(-2).join("."); // Últimos dos segmentos
  } else {
    dom.dominio = dominioCompleto;
  }
  return dom;
};

/**
 * Permite hacer un llamado ajax con metodo GET
 * @param {*} urlSend Corresponde a la URL relativa del servidor donde hacer el llamado
 * @param {*} SendData datos que se desean enviar (debe ser en modo objeto javascript)
 * @returns
 */
module.exports.obtenerJsonGET = async function (urlSend, SendData) {
  URLSend = process.env.VUE_APP_API_URL + urlSend;

  var returnData = {
    error: 0,
    message: "",
    data: "",
  };
  let dominioPrincipal = module.exports.obtenerDominio().dominio;
  var dataSend = null;
  try {

    var user = null;

    if (sessionStorage.getItem("usuarioDriverIn")) {
      user = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
    } else {
      user = {
        token: null,
      };
    }

    if (!user) {
      user = {
        token: null,
      };
    }

    dataSend = {
      dominio: dominioPrincipal,
      token: user.token,
      mode: "cors",
      modo: process.env.VUE_APP_VERSION,
    };
    for (const property in SendData) {
      dataSend[property] = SendData[property];
    }
    const url = new URL(URLSend);
    url.search = new URLSearchParams(dataSend);

    const data = await fetch(url);
    const json = await data.json();
    returnData = json;
  } catch (err) {
    returnData.error = -1;
    console.error("ERROR:",err,URLSend,dataSend);
    returnData.message =
      "ERROR: Servicio no disponible.";
  }
  return returnData;
};

/**
 * Permite hacer un llamado ajax con metodo POST
 * @param {*} urlSend Corresponde a la URL relativa del servidor donde hacer el llamado
 * @param {*} data2 datos que se desean enviar (debe ser en modo objeto FormData())
 * @returns
 */
module.exports.enviarJsonPOST = async function (urlSend, data2) {
  URLSend = process.env.VUE_APP_API_URL + urlSend;
  var returnData = {
    error: 0,
    message: "",
    data: "",
  };
  let setting = null;

  try {
    dominioPrincipal = module.exports.obtenerDominio().dominio;

    data2.append("dominio", dominioPrincipal);

    if (sessionStorage.getItem("usuarioDriverIn")) {
      user = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
    } else {
      user = null;
    }

    if (user) {
      if (user.token) {
        data2.append("token", user.token);
      } else {
        data2.append("token", null);
      }
    } else {
      data2.append("token", null);
    }

    setting = {
      method: "POST",
      modo: process.env.VUE_APP_VERSION,
      body: data2,
    };
    const resp = await fetch(URLSend, setting);
    if (!resp.ok || resp.headers.get("Content-Type") != "application/json") {
      throw new Error("Algo Salio mal");
    }
    const json = await resp.json();
    return json;
  } catch (err) {
    returnData.error = -1;
    console.error("ERROR:",err,URLSend,setting);
    returnData.message =
      "ERROR: Servicio no disponible.";
  }
  return returnData;
};

module.exports.formatoMonedaChile = function (numero, decimales) {
  if (isNaN(numero)) {
    return null;
  }

  // Formatear como moneda chilena
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: decimales,
  }).format(numero);
};

/**
 *
 * @param {String} val Fecha en formato 'YYYY-MM-DD HH:II:SS'
 * @param {boolean} bool verdadero si equiere segundos, falso si no.
 * @returns
 */
module.exports.formatoFechaHoraTXT = function (val) {
  let f = new Date(val);
  const anio = f.getFullYear();
  const mes = String(f.getMonth() + 1).padStart(2, "0");
  const dia = String(f.getDate()).padStart(2, "0");
  const hora = String(f.getHours()).padStart(2, "0");
  const minutos = String(f.getMinutes()).padStart(2, "0");
  const segundos = String(f.getSeconds()).padStart(2, "0");

  let fTXT = "";
  if (arguments > 1) {
    if (arguments[1]) {
      fTXT = `${dia}-${mes}-${anio} ${hora}:${minutos}:${segundos}`;
    } else {
      fTXT = `${dia}-${mes}-${anio} ${hora}:${minutos}`;
    }
  } else {
    fTXT = `${dia}-${mes}-${anio} ${hora}:${minutos}:${segundos}`;
  }

  return fTXT;
};

/**
 *
 * @param {String} val Fecha en formato 'YYYY-MM-DD HH:II:SS'
 * @param {boolean} bool verdadero si equiere segundos, falso si no.
 * @returns
 */
module.exports.formatoFechaTXT = function (val) {
  let f = new Date(val);
  const anio = f.getFullYear();
  const mes = String(f.getMonth() + 1).padStart(2, "0");
  const dia = String(f.getDate()).padStart(2, "0");

  let fTXT = "";
  fTXT = `${dia}-${mes}-${anio}`;

  return fTXT;
};

module.exports.formatoFechaVue = function (date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  if (month > 12) {
    month = 1;
    year++;
  }

  day = "" + (day < 10 ? "0" : "") + day;
  month = "" + (month < 10 ? "0" : "") + month;
  year = "" + (year < 999 ? "0" : "") + year;

  let fechaText = `${year}-${month}-${day}`;

  return fechaText;
};

module.exports.formatoFechaHoraVue = function (date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minute = date.getMinutes();
  if (month > 12) {
    month = 1;
    year++;
  }

  day = "" + (day < 10 ? "0" : "") + day;
  month = "" + (month < 10 ? "0" : "") + month;
  year = "" + (year < 999 ? "0" : "") + year;
  hour = "" + (hour < 10 ? "0" : "") + hour;
  minute = "" + (minute < 10 ? "0" : "") + minute;

  let fechaText = `${year}-${month}-${day} ${hour}:${minute}`;

  return fechaText;
};

module.exports.isMobile = function () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detectar dispositivos móviles y tabletas
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
};

module.exports.formatearNumero = function (numero) {
  let decimals = 0;
  if (arguments.length > 1) {
    decimals = arguments[1];
  }
  // Convertir el valor a número flotante
  const num = parseFloat(numero);

  // Validar que el número sea válido
  if (isNaN(num)) {
    throw new Error("El valor proporcionado no es un número válido");
  }

  // Formatear el número con separadores de miles y decimales
  return num
    .toFixed(decimals) // Ajustar a la cantidad de decimales
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".") // Agregar separador de miles (.)
    .replace(".", ",") // Cambiar el primer separador decimal a (,)
    .replace(/,(\d{3}(?!,))/g, ".$1"); // Cambiar los separadores de miles adicionales a (.).
};

/**
 * Calcula la distancia entre dos puntos geográficos usando la fórmula de Haversine.
 *
 * @param {{lat: number, lng: number}} coord1 - Coordenadas del primer punto (latitud y longitud).
 * @param {{lat: number, lng: number}} coord2 - Coordenadas del segundo punto (latitud y longitud).
 * @returns {number} - Distancia entre los dos puntos en metros.
 */
module.exports.calculoDistancia = function (coord1, coord2) {
  let distanciaEnMetros = 0;

  const R = 6371e3; // Radio de la Tierra en metros
  const lat1 = (coord1.lat * Math.PI) / 180;
  const lat2 = (coord2.lat * Math.PI) / 180;
  const deltaLat = ((coord2.lat - coord1.lat) * Math.PI) / 180;
  const deltaLng = ((coord2.lng - coord1.lng) * Math.PI) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distancia en metros
};

/**
 * Verifica qué tags están dentro de un radio de 50 metros de cualquier paso dado.
 *
 * @param {{lat: number, lng: number}[]} pasos - Lista de pasos con sus coordenadas geográficas.
 * @param {{lat: number, lng: number}[]} tags - Lista de tags con sus coordenadas geográficas.
 * @returns {{lat: number, lng: number}[]} - Lista de tags que están dentro del rango de 50 metros.
 */
module.exports.revisaExistenciaTag = function (pasos, tags) {
  let tagPago = [];
  pasos.forEach((step) => {
    let puntoPaso = { lat: step.lat, lng: step.lng };

    tags.forEach((tag) => {
      console.log("tag", tag);
      let puntoTag = { lat: tag.lat, lng: tag.lng };

      let Distancia = module.exports.calculoDistancia(puntoPaso, puntoTag);

      let sino = false;

      if (Distancia <= 10) {
        module.exports.agregarSiNoExiste(tagPago, tag);
        sino = true;
      }
      console.log("distancia", puntoPaso, puntoTag, Distancia, sino);
    });
  });
  return tagPago;
};

/**
 * Decodifica una polyline encriptada utilizando el algoritmo de codificación de Google.
 * @param {string} encodedPolyline - La polyline codificada como una cadena de caracteres.
 * @returns {Array} - Un arreglo de objetos con las propiedades {lat, lng}.
 */
module.exports.decodePolyline = function (encodedPolyline) {
  let index = 0;
  const points = [];
  let lat = 0;
  let lng = 0;

  while (index < encodedPolyline.length) {
    let b,
      shift = 0,
      result = 0;
    // Decodificar latitud
    do {
      b = encodedPolyline.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    lat += result & 1 ? ~(result >> 1) : result >> 1; // Decodificar la latitud
    shift = 0;
    result = 0;

    // Decodificar longitud
    do {
      b = encodedPolyline.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    lng += result & 1 ? ~(result >> 1) : result >> 1; // Decodificar la longitud

    points.push({ lat: lat / 1e5, lng: lng / 1e5 }); // Ajustar la latitud y longitud
  }

  return points;
};

/**
 * Agrega un objeto a un arreglo si no existe previamente basado en su id.
 * @param {Array} array - El arreglo al que se va a agregar el objeto.
 * @param {Object} objeto - El objeto que se quiere agregar al arreglo.
 */
module.exports.agregarSiNoExiste = function (array, objeto) {
  // Verificar si el objeto con el mismo id ya existe en el arreglo
  const existe = array.some((item) => item.id === objeto.id);

  // Si no existe, agregar el objeto al arreglo
  if (!existe) {
    array.push(objeto);
  }
};
