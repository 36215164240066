<template>
  <v-container :class="isMobile ? 'px-0 py-0' : ''">
    <v-form
      ref="form_ingreso"
      @submit.prevent="guardarCombustible"
      v-model="validForm"
    >
      <v-row dense class="pt-4">
        <v-col cols="12" md="6">
          <cbo_combobox
            labelSet="Vehiculo"
            :itemsSet="vehiculo_items"
            :valorSet="vehiculo_cod"
            :readonlySet="!cambiavehiculo"
            :requeridoSet="true"
            @ChangeValue="ChangeVehiculo"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6">
          <txt_textos
            ref="comprobante"
            labelSet="Nro Comprobante"
            :requeridoSet="true"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6">
          <txt_archivos
            ref="imagen"
            labelSet="Imagen respaldo"
            :multiple-set="true"
            acceptSet="image/*"
            :maxFilesSet="2"
            :requeridoSet="true"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6">
          <fec_fechahora
            labelSet="Fecha"
            ref="fechavale"
            :requeridoSet="true"
            :maxFecSet="fechaMaxima"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_textoGrande
            ref="descripcion"
            labelSet="Descripción del gasto"
            :valorSet="descripcion"
            :requeridoSet="true"
            :maxLengthSet="1000"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_checkbox
            ref="efectivo"
            :valor-set="efectivo"
            labelSet="¿Uso dinero de carrera en efectivo?"
            :inlineSet="true"
            :opciones="esefectivoitem"
            :valorSet="esefectivo"
            :readonlySet="!edit"
          />
        </v-col>
        <v-col cols="4" md="2">
          <num_numero
            ref="porvehiculo"
            labelSet="% Vehículo"
            :requeridoSet="true"
            :readonlySet="!esaprobacion"
            :valorSet="porvehiculo"
            :valorMinimoSet="0"
            :valorMaximoSet="100"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="4" md="2">
          <num_numero
            ref="porconductor"
            labelSet="% Conductor"
            :requeridoSet="true"
            :readonlySet="!esaprobacion"
            :valorMinimoSet="0"
            :valorMaximoSet="100"
            :valorSet="porconductor"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="4" md="2">
          <num_numero
            ref="portitular"
            labelSet="% Titular"
            :requeridoSet="true"
            :readonlySet="!esaprobacion"
            :valorMinimoSet="0"
            :valorMaximoSet="100"
            :valorSet="portitular"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6">
          <num_numero
            ref="precio"
            labelSet="Valor"
            :requeridoSet="true"
            :valorMinimoSet="1000"
            :valorMaximoSet="999999"
            :validatingSet="validando"
          />
        </v-col>
      </v-row>
      <v-row dense class="text-caption pt-3">
        <v-col cols="12" v-if="isMobile">
          <btn_submit
            labelSet="Guardar"
            @accion="guardarCombustible"
            :loading="validando"
            append-icon="mdi-content-save-move"
          />
        </v-col>
        <v-col :cols="isMobile ? 12 : 6">
          <btn_cancel
            labelSet="Calcelar"
            @accion="volverMenu"
            :loading="validando"
            preIcon="mdi-cancel"
          />
        </v-col>
        <v-col cols="6" v-if="!isMobile">
          <btn_submit
            labelSet="Guardar"
            @accion="guardarCombustible"
            :loading="validando"
            append-icon="mdi-content-save-move"
          />
        </v-col>
      </v-row>
      <mensajeUsuario />
      <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="salirApp" />
      <mensajeUsuario ref="mensajeUsuario" />
      <mensajeUsuario ref="mensajeUsuarioOk" @cerrar="volverMenu" />
    </v-form>
  </v-container>
</template>
  
  <script>
import {
  obtenerJsonGET,
  enviarJsonPOST,
  paletColor,
  isMobile,
} from "@/helpers/helpers";
import txt_textos from "@/components/form/textos.vue";
import fec_fechahora from "@/components/form/fechaHora.vue";

import txt_checkbox from "@/components/form/checkBox.vue";
import num_numero from "@/components/form/numero.vue";
import cbo_combobox from "@/components/form/combobox.vue";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import txt_archivos from "@/components/form/archivos.vue";
import btn_submit from "@/components/form/buttonSubmitform.vue";
import btn_cancel from "@/components/form/buttonCancelform.vue";
import txt_textoGrande from "@/components/form/textoGrande.vue";
export default {
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    entorno: process.env.NODE_ENV,
    isMobile: isMobile(),
    paletColor: paletColor,
    esefectivo: "0",
    esefectivoitem: [
      { valor: "0", label: "No" },
      { valor: "1", label: "Si" },
    ],
    esaprobacion: false,
    porvehiculo: "100",
    porconductor: "0",
    portitular: "0",
    edit: true,

    vehiculo_cod: null,
    empresa_cod: null,
    vehiculo_items: null,
    cambiavehiculo: true,
    fechaMaxima: null,
    validando: false,
    validForm: null,
  }),
  components: {
    txt_textos,
    fec_fechahora,
    txt_checkbox,
    num_numero,
    txt_textoGrande,
    cbo_combobox,
    mensajeUsuario,
    txt_archivos,
    btn_submit,
    btn_cancel,
  },
  mounted: async function () {
    (this.fechaMaxima = new Date()), await this.obtenerVehiculos();
  },
  methods: {
    guardarCombustible: async function () {
      this.validando = true;
      let { valid } = await this.$refs.form_ingreso.validate();
      if (valid) {
        let fd = new FormData();
        if (this.$refs.imagen.valor) {
          for (var x = 0; x < this.$refs.imagen.valor.length; x++) {
            if(this.entorno.toLowerCase() != 'development'){
              let size = this.$refs.imagen.valor[x].size / 1024 / 1024;
              let date = this.$refs.imagen.valor[x].lastModifiedDate;

              const timeDifference = Math.abs(new Date() - date);

              // 15 minutos en milisegundos (15 minutos * 60 segundos * 1000 milisegundos)
              const fifteenMinutesInMs = 30 * 60 * 1000;

              if (timeDifference > fifteenMinutesInMs) {
                this.$refs.mensajeUsuario.mostrar(
                  "La imagen es demasiado antigua, por favor tome una nueva imagen.",
                  "Advertencia"
                );
                this.validando = false;
                return this.validando;
              } else if (size > 5) {
                this.$refs.mensajeUsuario.mostrar(
                  "Los archivos adjuntos no pueden superar 5MB, por favor corregir.",
                  "Advertencia"
                );
                this.validando = false;
                return this.validando;
              }
            }
            fd.append("imagen[]", this.$refs.imagen.valor[x]);
          }
        }
        fd.append("vehiculo_cod", this.vehiculo_cod.cod);
        fd.append("empresa_cod", this.empresa_cod);
        fd.append("comprobante", this.$refs.comprobante.valor);
        fd.append("fechavale", this.$refs.fechavale.valor);
        fd.append("precio", this.$refs.precio.valor);
        fd.append("efectivo", this.$refs.efectivo.valor);
        fd.append("porvehiculo", this.$refs.porvehiculo.valor);
        fd.append("porconductor", this.$refs.porconductor.valor);
        fd.append("portitular", this.$refs.portitular.valor);
        fd.append("descripcion", this.$refs.descripcion.valor);
        
        

        let data = await enviarJsonPOST(
          "/gasto/guardargasto/",
          fd
        );
        if (data.error == 0) {
          this.$refs.mensajeUsuarioOk.mostrar(data.message, "Información");
        } else if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message, "Información");
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message, "Información");
        }
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Debe ingresar la información requerida."
        );
      }
      this.validando = false;
    },
    obtenerVehiculos: async function () {
      let data = await obtenerJsonGET(
        "/vehiculo/listadovehiculoconductorcombobox/"
      );
      if (data.error == "0") {
        this.vehiculo_items = data.data;
        console.log(this.vehiculo_items);
        if (data.data.length == 1) {
          this.vehiculo_cod = data.data[0];
          this.minKM = data.data[0].kilometraje;
          this.empresa_cod = data.data[0].empresa_cod;
          this.cambiavehiculo = false;
        }
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    ChangeVehiculo: function (val) {
      this.minKM = val.kilometraje;
      this.empresa_cod = val.empresa_cod;
    },
    volverMenu: function () {
      this.$router.go(-1);
    },
    salirApp: function () {
      this.$router.replace("/");
    },
  },
};
</script>
  
  <style>
</style>